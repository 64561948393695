<template>
  <div>
    <!-- desktop -->
    <div class="hidden lg:flex">
      <transition name="slide-top">
        <div
          v-if="show"
          class="fixed top-0 w-auto mx-auto inset-x-0 z-40"
          style="width: max-content; max-width: 90%"
        >
          <div
            class="flex items-center text-white text-center z-20 pl-8 text-base round-bar"
            :class="[`bg-${colour}`, { 'pr-8': !closable }]"
          >
            <div class="mt-2 mb-2">
              <span v-for="(item, index) in content" :key="index">
                <template v-if="item.link">
                  <a
                    @click.stop="linkClick(item.link.to)"
                    class="underline cursor-pointer mx-1"
                    >{{ item.link.text }}</a
                  >
                </template>
                <template v-else>
                  {{ item.text }}
                </template>
              </span>
            </div>
            <div
              v-if="closable"
              v-on:click="closeBar"
              class="text-white font-bold cursor-pointer ml-2 my-auto pr-6"
            >
              x
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- mobile -->
    <div class="flex lg:hidden">
      <transition name="slide-bottom">
        <div
          v-if="show"
          class="fixed bottom-0 w-auto mx-auto inset-x-0 z-50"
          style="width: max-content; max-width: 100%"
        >
          <div
            class="flex items-center text-white text-center z-20 pl-8 text-base round-bar-mobile"
            :class="[`bg-${colour}`, { 'pr-8': !closable }]"
          >
            <div class="mt-2 mb-2">
              <span v-for="(item, index) in content" :key="index">
                <template v-if="item.link">
                  <a
                    @click.stop="linkClick(item.link.to)"
                    class="underline cursor-pointer mx-1"
                    >{{ item.link.text }}</a
                  >
                </template>
                <template v-else>{{ item.text }}</template>
              </span>
            </div>
            <div
              v-if="closable"
              v-on:click="closeBar"
              class="text-white font-bold cursor-pointer ml-2 my-auto pr-6"
            >
              x
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import router from '@/router';

export default {
  name: 'Notificationbar',
  props: {
    closable: {
      type: Boolean,
      default: true,
    },
    type: {
      // error, warning, validation
      type: String,
      default: 'error',
    },
    errorMsg: {
      type: String,
      default: '',
    },
    errorMsgDetails: {
      type: String,
      default: '',
    },
    content: {
      type: Array,
      default: () => [],
    },
    showOnLoad: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: false,
      palette: {
        error: { colour: 'alerterror', light: 'alerterrorlight' },
        warning: { colour: 'alertwarning', light: 'alertwarninglight' },
        validation: {
          colour: 'alertvalidation',
          light: 'alertvalidationlight',
        },
      },
    };
  },
  mounted() {
    if (this.showOnLoad) this.showBar();
  },
  methods: {
    linkClick(to) {
      router.replace({ name: to });
    },
    showBar() {
      this.show = true;

      if (this.$gAuth) {
        // this is so shorybook works
        const text = this.content
          .map((item) => {
            if (item.link) return item.link.text;
            else return item.text;
          })
          .join('');
        const props = {
          errorMsg: this.errorMsg || text,
          text: text,
        };
        if (this.errorMsgDetails) props.errorMsgDetails = this.errorMsgDetails;

        window.meetric.track('Error', props);
      }
    },

    closeBar() {
      this.show = false;
      this.$emit('close');
    },
  },
  computed: {
    colour() {
      return this.palette[this.type].colour;
    },
    colourLight() {
      return this.palette[this.type].light;
    },
  },
};
</script>

<style scoped>
.slide-top-enter-active,
.slide-top-leave-active,
.slide-bottom-enter-active,
.slide-bottom-leave-active {
  transition: transform 0.8s ease;
}
.slide-top-enter,
.slide-top-leave-to {
  transform: translateY(-100%);
}
.slide-bottom-enter,
.slide-bottom-leave-to {
  transform: translateY(100%);
}
.round-bar {
  @apply rounded-tl-2xl -mt-3;
  padding-top: 0.65rem;
  border-bottom-left-radius: 3rem;
  border-bottom-right-radius: 3rem;
}

.round-bar-mobile {
  @apply rounded-bl-2xl -mb-3;
  padding-bottom: 0.65rem;
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
}
</style>
