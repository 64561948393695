import { getPreferences } from '@/api/Cherry';

const getDefaultState = () => {
  return {
    preferences: {},
    calendars: [],
    welcomeEventsId: [],
    welcomeEventId: '', //main welcome event
    loaded: false,
    isExtension: false,
    isMobile: false,
    logoutCount: -1,
    notion: {},
    slack: {},
    plan: {},
    offlineAccess: false,
  };
};

export default {
  state: getDefaultState(),

  getters: {
    preferencesLoaded: (state) => state.loaded,
    userPreferences: (state) => state.preferences,
    preferenceDailySummary: (state) => state.preferences.daily_summary || false,
    preferenceDarkMode: (state) => state.preferences.dark_mode || 'system',
    preferenceStartPage: (state) => state.preferences.start_page || 'profile',
    preferenceTimemachineRecurring: (state) => {
      return state.preferences.timemachine_recurring != undefined
        ? state.preferences.timemachine_recurring
        : true;
    },
    preferenceTimemachineGuests: (state) => {
      return state.preferences.timemachine_guests != undefined
        ? state.preferences.timemachine_guests
        : true;
    },
    preferenceTimemachineSummary: (state) => {
      return state.preferences.timemachine_summary != undefined
        ? state.preferences.timemachine_summary
        : true;
    },
    preferenceShowSolo: (state) => {
      return state.preferences.show_solo != undefined
        ? state.preferences.show_solo
        : false;
    },
    preferenceShowAllDay: (state) => state.preferences.show_all_day || false,
    // Action page preferences
    preferenceActionsGroupBy: (state) =>
      state.preferences.actions_groupby || 'byDate',
    preferenceActionsFilterOwn: (state) => {
      return state.preferences.actions_filter_own != undefined
        ? state.preferences.actions_filter_own
        : true;
    },
    preferenceActionsFilterOthers: (state) => {
      return state.preferences.actions_filter_others != undefined
        ? state.preferences.actions_filter_others
        : true;
    },
    preferenceActionsFilterCompleted: (state) => {
      return state.preferences.actions_filter_completed != undefined
        ? state.preferences.actions_filter_completed
        : false;
    },
    // Default view preferences
    preferenceNotesDefaultJustMe: (state) => {
      return state.preferences.new_note_option != undefined
        ? state.preferences.new_note_option
        : false;
    },

    selectedCalendars: (state) => state.calendars,
    welcomeEventsId: (state) => state.welcomeEventsId,
    welcomeEventId: (state) => state.welcomeEventId,
    logoutCount: (state) => state.logoutCount,
    isExtension: (state) => state.isExtension,
    isMobile: (state) => state.isMobile,
    notionDetails: (state) => state.notion,
    slackDetails: (state) => state.slack,
    plan: (state) => state.plan?.type,
    colleagues: (state) => state.colleagues,
    planCancel: (state) => state.plan?.cancel_at,
    planTrial: (state) => state.plan?.trial_end,
    hasOfflineAccess: (state) => state.offlineAccess,
  },

  mutations: {
    RESET_STATE(state) {
      Object.assign(state, getDefaultState());
    },
    savePreferences(state, preferences) {
      state.preferences = preferences;
      state.loaded = true;
    },
    saveCalendars(state, calendars) {
      if (calendars.includes('primary')) {
        calendars = ['primary', ...calendars.filter((c) => c != 'primary')];
      }

      state.calendars = calendars.map((c) => encodeURIComponent(c));
    },
    saveWelcomeEventsId(state, ids) {
      state.welcomeEventsId = ids;
    },
    saveMainWelcomeEventId(state, id) {
      state.welcomeEventId = id;
    },
    saveLogoutCount(state, count) {
      state.logoutCount = count == undefined ? 0 : count;
    },
    saveIsExtension(state, extension) {
      state.isExtension = extension;
    },
    saveIsMobile(state, mobile) {
      state.isMobile = mobile;
    },
    setNotionIntegration(state, notionDetails) {
      state.notion = notionDetails;
    },
    setSlackIntegration(state, slackDetails) {
      state.slack = slackDetails;
    },
    savePlan(state, plan) {
      state.plan = plan;
    },
    saveColleagues(state, colleagues) {
      state.colleagues = colleagues;
    },
    saveOfflineAccess(state, offlineAccess) {
      state.offlineAccess = !!offlineAccess;
    },
  },

  actions: {
    userResetState({ commit }) {
      commit('RESET_STATE');
    },
    fetchPreferences: async function (context) {
      return getPreferences().then((prefs) => {
        context.commit('savePreferences', prefs);
        return prefs;
      });
    },
  },
};
